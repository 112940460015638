var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-select',{attrs:{"items":_vm.items,"rules":_vm.rules,"label":_vm.label ? _vm.label : '3. Şahıs',"item-text":"tarafAdi","item-value":"guncelKisiKurumId","menu-props":{
      auto: true,
      offsetY: true,
      maxWidth: '45%'
    },"outlined":"","dense":""},on:{"input":_vm.onKurumChange},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [(_vm.isShowUyapIcon)?_c('div',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-icon',_vm._g({attrs:{"color":"orange darken-2"}},_vm.tooltip ? on : null),[_vm._v(" mdi-alpha-u-box ")])]}}],null,false,946754143)},[_vm._v(" "+_vm._s(_vm.tooltip)+" ")])],1):_vm._e()]},proxy:true},{key:"append-outer",fn:function(){return [(_vm.isShowTarafEkle)?_c('div',{staticStyle:{"padding-top":"0","margin-top":"1px !important"}},[_c('form-dialog',{attrs:{"title":"Taraf Ekle","tooltip":"Taraf Ekle","icon":"mdi-plus","button-theme":"gray"},on:{"success":_vm.handleSuccess},scopedSlots:_vm._u([{key:"default",fn:function({ formData, onSuccess, onClose }){return [_c('ucuncu-taraf-form',{on:{"onSuccess":(newData) => {
          onSuccess(newData);
          onClose();
        },"onClose":onClose},model:{value:(formData),callback:function ($$v) {formData=$$v},expression:"formData"}})]}}],null,false,2389638559)})],1):_vm._e()]},proxy:true}]),model:{value:(_vm.localValue),callback:function ($$v) {_vm.localValue=$$v},expression:"localValue"}})
}
var staticRenderFns = []

export { render, staticRenderFns }