

import {Component, Mixins, Prop} from "vue-property-decorator";
//@ts-ignore
import {GetDosyaTarafListesiWithTarafRolCevapRow} from "@/plugins/uyap-plugin/uyap/GetDosyaTarafListesiWithTarafRol";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";
import FormDialog from "@/components/dialogs/FormDialog.vue";
import UcuncuTarafForm from "@/components/forms/form-wizard/eicrapro/UcuncuTarafForm.vue";

@Component({
  components: { UcuncuTarafForm, FormDialog }
})

export default class DosyaAraciKisiKurumTarafListesiPicker extends Mixins(SimpleInputMixin) {
  @Prop({ required: true }) dosyaId!: number;
  @Prop() rules!: string;
  @Prop() showUyapIcon!: string | boolean;
  @Prop() tooltip!: string;
  @Prop() showTarafEkle!: string | boolean;
  @Prop() label!: string;

  items: Array<GetDosyaTarafListesiWithTarafRolCevapRow> = [];
  localValue: any = null;



  get isShowUyapIcon() {
    return this.showUyapIcon || this.showUyapIcon === "";
  }

  get isShowTarafEkle() {
    return this.showTarafEkle || this.showTarafEkle === "";
  }

  onKurumChange(v: GetDosyaTarafListesiWithTarafRolCevapRow) {
    const selected = this.items.find(item => item.guncelKisiKurumId == this.localValue);
    this.$emit("kurumAdi", selected?.tarafAdi);
    this.input();
  }

  created() {
    this.load();
  }

  async load() {
    console.log("load() çağrıldı...");
    const response = await this.$uyap.GetDosyaTarafListesiWithTarafRol().run({
      dosyaId: this.dosyaId,
      islemTuru: 71
    });
    console.log("Response from API:", response);

    this.items = response;
    console.log("load() sonrası this.items:", this.items);
  }

  async handleSuccess(newPersonData: GetDosyaTarafListesiWithTarafRolCevapRow) {
    console.log("Gelen yeni kişi:", newPersonData);
    await this.load();
    this.localValue = newPersonData.guncelKisiKurumId;
    console.log("load() sonrası items:", this.items);
  }


}
